import {
  Flex,
  Item,
  ComboBox,
  Text,
  useAsyncList,
  Switch,
  Tooltip,
  TooltipTrigger,
  View,
  ActionButton,
  Link as V3Link,
  Divider,
  Header,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import {
  error as ErrorToast,
  // success as SuccessToast,
} from "@react/react-spectrum/Toast";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import Edit from "@spectrum-icons/workflow/Edit";
import {
  GoldenRecordsColumn,
  GoldenRecordSourceSystemAccountsColumn,
} from "../../../constants/Columns";
import { ManageAccountMappingPath } from "../../../constants/Routes";
import { goldenRecordApi } from "../../../api/goldenRecordApi";
import { hierarchyApi } from "../../../api/hierarchyApi";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import Table from "../../../components/Common/Table";
import {
  // SourceSystemAccountsLabel,
  // OrgEntityAccountsLabel,
  GoldenRecordAccountsLabel,
  GoldenRecordSourceSystemAccountsLabel,
} from "../../../constants/SlidingPaneLabels";
import CamSlidingPane from "../../../components/Common/CamSlidingPane/CamSlidingPane";
import AccountMappingForm from "../../../components/Forms/AccountMappingForm/AccountMappingForm";

export default function ViewGoldenRecords() {
  const [goldenRecordList, setGoldenRecordList] = useState([]);
  const [fetchGoldenRecordRequest, setFetchGoldenRecordRequest] = useState({
    page: 0,
    size: 10,
  });
  // const fetchGoldenRecordRequest = { page: 0, size: 10 };
  const [sourceSystemAccounts, setSourceSystemAccounts] = useState([]);
  const [isSearchLoading, setSearchLoading] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filterByDuns, setFilterByDuns] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState("");

  const [slidingPaneData, setSlidingPaneData] = useState({});
  const [slidingPaneLabels, setSlidingPaneLabels] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const goldenRecordsColumn = [
    ...GoldenRecordsColumn(),
    {
      name: "Manage",
      uid: "manage",
      defaultWidth: 95,
    },
  ];
  const sourceSystemColumn = GoldenRecordSourceSystemAccountsColumn();

  const [isPageLoading, setPageLoading] = useState(false);
  const { authState } = useOktaAuth();
  const history = useHistory();

  const orgListSearchResultSize = 10;

  useEffect(() => {
    if (selectedOrgId !== "") {
      setPageLoading(true);
      goldenRecordApi
        .fetchGoldenRecordDetails(
          { org_entity_id: selectedOrgId },
          authState.accessToken
        )
        .then((data) => {
          setPageLoading(false);
          const sourceSystemAccountsWithId = data.source_system_accounts.map(
            (acc) => ({
              ...acc,
              id: acc.source_system + acc.account_id,
            })
          );
          setSourceSystemAccounts(sourceSystemAccountsWithId);
        })
        .catch((error) => {
          setPageLoading(false);
          ErrorToast(
            error.response?.data?.message ||
              "Failed to fetch source system accounts!",
            {
              timeout: 5000,
            }
          );
        });
    }
  }, [selectedOrgId]);

  const createStandardizedAccount = (account) => {
    const standardizedAccount = account;
    standardizedAccount.address_key = `${
      account.street ? account.street : ""
    } ${account.street_sup ? account.street_sup : ""} ${
      account.city ? account.city : ""
    }, ${account.state ? account.state : ""} ${
      account.country ? account.country : ""
    } ${account.postalcode ? account.postalcode : ""}`;
    const createdDate = new Date(account.createddatetime);
    standardizedAccount.sliding_created_at = `${createdDate.toUTCString()} by ${
      account.createdby
    }`;
    const modifiedDate = new Date(account.modifieddatetime);
    standardizedAccount.sliding_last_updated = `${modifiedDate.toUTCString()} by ${
      account.modifiedby
    }`;
    standardizedAccount.account_name = account.org_name_1;
    standardizedAccount.street = account.street_1;
    return standardizedAccount;
  };

  const createSourceSystem = (sourceSystemList) => {
    sourceSystemList.forEach((account, index) => {
      const sourceSystemAccount = account;
      sourceSystemAccount.index = index;
      const createdDate = new Date(account.createddatetime);
      const modifiedDate = new Date(account.modifieddatetime);
      sourceSystemAccount.sliding_created_at = `${createdDate.toUTCString()} by ${
        account.createdby
      }`;
      sourceSystemAccount.sliding_last_updated = `${modifiedDate.toUTCString()} by ${
        account.modifiedby
      }`;
      const address = account.address[0];
      sourceSystemAccount.address_key = `${
        address.street ? address.street : ""
      } ${address.street_sup ? address.street_sup : ""} ${
        address.city ? address.city : ""
      }, ${address.state ? address.state : ""} ${
        address.country ? address.country : ""
      } ${address.postalcode ? address.postalcode : ""}`;
      sourceSystemAccount.street = address.street ? address.street : "";
      sourceSystemAccount.street_sup = address.street_sup
        ? address.street_sup
        : "";
      sourceSystemAccount.city = address.city ? address.city : "";
      sourceSystemAccount.state = address.state ? address.state : "";
      sourceSystemAccount.postalcode = address.postalcode
        ? address.postalcode
        : "";
      sourceSystemAccount.country = address.country ? address.country : "";
      sourceSystemAccount.id = account.source_system.concat(
        ":",
        account.account_id
      );
    });
    return sourceSystemList;
  };

  const handleManageStandardizedAccount = (row) => {
    // 1. fetch source systems
    setPageLoading(true);
    goldenRecordApi
      .fetchGoldenRecordDetails(
        { org_entity_id: row.org_entity_id },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        const sourceSystems = createSourceSystem(data.source_system_accounts);
        // 2. update org account to be in correct format
        const orgEntity = [createStandardizedAccount(row)];
        history.push(ManageAccountMappingPath, {
          orgEntityAccInfo: orgEntity,
          sourceSystemAccInfo: sourceSystems,
          dunsId: row.duns_number,
          goldenRecordPage: true,
        });
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message ||
            "Failed to fetch source system accounts!",
          {
            timeout: 5000,
          }
        );
      });
  };

  const handleOpenDialog = (data, accountType) => {
    let label = [];
    if (accountType === "org") {
      label = GoldenRecordAccountsLabel();
    } else {
      label = GoldenRecordSourceSystemAccountsLabel();
    }

    setSlidingPaneData(data);
    setSlidingPaneLabels(label);
    setOpenDialog(!openDialog);
  };

  const renderCell = (colKey, row) => {
    if (colKey === "manage") {
      return (
        <TooltipTrigger>
          <ActionButton
            isQuiet
            onPress={() => handleManageStandardizedAccount(row)}
          >
            <View>
              <Edit color="informative" />
            </View>
          </ActionButton>
          <Tooltip>Manage CAM Standardized Account</Tooltip>
        </TooltipTrigger>
      );
    }
    if (colKey === "org_entity_id") {
      return (
        <Text>
          <V3Link isQuiet onPress={() => handleOpenDialog(row, "org")}>
            {row[colKey]}
          </V3Link>
        </Text>
      );
    }
    if (colKey === "account_id") {
      return (
        <Text>
          <V3Link isQuiet onPress={() => handleOpenDialog(row, "sourcesystem")}>
            {row[colKey]}
          </V3Link>
        </Text>
      );
    }
    if (colKey === "org_address") {
      return (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          style={{ cursor: "text", WebkitUserSelect: "text" }}
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Text>{`${row?.street_1}, ${row?.city}, ${row?.state}, ${row?.country}, ${row?.postalcode}`}</Text>
        </span>
      );
    }
    if (colKey === "address") {
      const address = row[colKey][0];
      return (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          style={{ cursor: "text", WebkitUserSelect: "text" }}
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Text>
            {`${address.street ? address.street : ""} ${
              address.street_sup ? address.street_sup : ""
            } ${address.city ? address.city : ""}, ${
              address.state ? address.state : ""
            } ${address.country ? address.country : ""} ${
              address.postalcode ? address.postalcode : ""
            }`}
          </Text>
        </span>
      );
    }
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text>{row[colKey]}</Text>
      </span>
    );
  };

  const targetAccountList = useAsyncList({
    async load({ filterText }) {
      if (!filterText)
        return {
          items: [],
        };
      const json = await hierarchyApi.searchHMS(
        {
          name: filterText,
          hierarchyName: "ECH Hierarchy",
          dunsFilter: filterByDuns,
          resultsSize: 50,
          excludeSourceSystem: true,
        },
        authState.accessToken
      );
      const results = json?.results || [];
      return {
        items: results,
      };
    },
  });

  const orgAccountList = useAsyncList({
    initialFilterText: "",
    async load({ filterText, cursor }) {
      let json = {
        results: [],
      };
      const request = cursor || fetchGoldenRecordRequest;
      if (filterText !== "") {
        setSearchLoading("loading");
        json = await goldenRecordApi.fetchGoldenRecords(
          request,
          authState.accessToken
        );
      }
      const results = json?.results.golden_records || [];

      setSearchLoading("");
      setFetchGoldenRecordRequest({
        ...fetchGoldenRecordRequest,
        page: fetchGoldenRecordRequest.page + 1,
      });
      return {
        items: results,
        cursor:
          results.length < orgListSearchResultSize
            ? null
            : {
                ...fetchGoldenRecordRequest,
                page: fetchGoldenRecordRequest.page + 1,
              },
      };
    },
    getKey: (item) => item?.id,
  });

  useEffect(() => {
    orgAccountList.setFilterText(fetchGoldenRecordRequest);
  }, []);

  useEffect(() => {
    if (selectedAccountId !== "") {
      setPageLoading(true);
      goldenRecordApi
        .fetchGoldenRecordDetails(
          { org_entity_id: selectedAccountId },
          authState.accessToken
        )
        .then((data) => {
          setPageLoading(false);
          const sourceSystemAccountsWithId = data.source_system_accounts.map(
            (acc) => ({
              ...acc,
              id: acc.source_system + acc.account_id,
            })
          );
          setSourceSystemAccounts(sourceSystemAccountsWithId);
          setGoldenRecordList([data.org_entity]);
        })
        .catch((error) => {
          setPageLoading(false);
          ErrorToast(
            error.response?.data?.message ||
              "Failed to fetch source system accounts!",
            {
              timeout: 5000,
            }
          );
        });
    }
  }, [selectedAccountId]);

  return (
    <Flex marginTop="size-100" direction="column">
      <LoadingDialog isOpen={isPageLoading} />
      <Flex alignItems="baseline" gap="size-100" height="40px" direction="row">
        <ComboBox
          label={
            <Flex height="32px" alignItems="center">
              <Flex width="200px">
                <Text UNSAFE_style={{ fontSize: "14px" }}>Search account</Text>
              </Flex>
              <Flex direction="column" alignItems="end" width="200px">
                <Switch
                  isSelected={filterByDuns}
                  onChange={setFilterByDuns}
                  marginEnd="-8px"
                >
                  <Text>DUNS Only</Text>
                </Switch>
              </Flex>
            </Flex>
          }
          placeholder="Search by org account name, orgid, or duns"
          menuTrigger="focus"
          onSelectionChange={(val) => {
            setSelectedOrgId(val);
            setSelectedAccountId(val);
          }}
          inputValue={searchInput}
          onInputChange={(val) => {
            targetAccountList.setFilterText(val);
            setSearchInput(val);
          }}
          items={targetAccountList.items}
          loadingState={targetAccountList.loadingState}
          width="400px"
        >
          {(item) =>
            item !== undefined && (
              <Item key={item.id}>
                <Text>{`${item.name} | orgid: ${item.id} | duns: ${
                  item.duns_number === "" ? "null" : item.duns_number
                }`}</Text>
              </Item>
            )
          }
        </ComboBox>
      </Flex>
      <Flex
        marginTop="size-700"
        UNSAFE_className="stepper-box"
        UNSAFE_style={{
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        height={
          sourceSystemAccounts.length === 0
            ? "static-size-6000"
            : "static-size-4600"
        }
      >
        <Table
          columns={goldenRecordsColumn}
          rows={
            goldenRecordList.length > 0
              ? goldenRecordList
              : orgAccountList.items
          }
          /* eslint-disable react/jsx-props-no-spreading */
          {...(goldenRecordList.length === 0
            ? {
                loadingState:
                  isSearchLoading !== ""
                    ? isSearchLoading
                    : orgAccountList.loadingState,
              }
            : {})}
          onLoadMore={
            goldenRecordList.length > 0 ? null : orgAccountList.loadMore
          }
          renderCell={renderCell}
          selectionMode="none"
          density="compact"
          onAction={setSelectedOrgId}
        />
      </Flex>
      {sourceSystemAccounts.length > 0 && (
        <Flex direction="column">
          <Divider size="M" marginTop="size-600" />
          {sourceSystemAccounts.length > 0 && (
            <Header id="asa" marginTop="10px">
              {`Source Accounts For ORG ID: ${selectedOrgId} (${sourceSystemAccounts.length} total)`}
            </Header>
          )}
          {sourceSystemAccounts.length > 0 && (
            <Flex
              marginTop="size-300"
              UNSAFE_className="stepper-box"
              UNSAFE_style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
              }}
              height={
                sourceSystemAccounts?.length < 4 ? "fit-content" : "300px"
              }
            >
              <Table
                id="accountrelTable"
                columns={sourceSystemColumn}
                rows={sourceSystemAccounts}
                renderCell={renderCell}
                selectionMode="none"
                density="compact"
                // overflowMode={isORGLookup ? "truncate" : "wrap"}
              />
            </Flex>
          )}
        </Flex>
      )}
      {openDialog && (
        <CamSlidingPane
          isPaneOpenFlag={openDialog}
          handleOpenDialog={() => setOpenDialog(false)}
          paneTitle={slidingPaneData.account_name}
          Component={
            <AccountMappingForm
              data={slidingPaneData}
              labels={slidingPaneLabels}
            />
          }
        />
      )}
    </Flex>
  );
}
